<template>
  <div class="forecast_page">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
      <van-tabs ref="get_title" v-model="active" animated title-inactive-color="#000000" title-active-color="#3abaf7" line-width="42%" line-height="3px" @click="onTabClick">
        <van-tab v-for="(item, index) in tab" :key="index" :title="item.name"></van-tab>
      </van-tabs>
    </div>
    <ol class="list_box">
      <van-list ref="list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <li class="list van-hairline--bottom" v-for="(item, index) in list" :key="index" @click="getDetail(index)" >
          <van-image class="photo" fit="cover" width="25vw" height="22vw" :src="item.headImgUrl" />
          <div class="cnt">
            <p class="name van-ellipsis">{{item.artTitle}}</p>
            <p class="txt">{{item.lotteryKind == '5'? '排列五' : '七星彩'}}</p>
            <p class="txt">{{item.artDate | setDate}} <span class="price">{{item.price == '0' ? '免费' : '月亮：' + (item.price/100)}}</span></p>
          </div>
        </li>
      </van-list>
    </ol>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'


export default {
  components: {
    vHeader
  },
  data() {
    return {
      scroll:0,
      title: this.$route.meta.title,
      tab: [
        {
          name: '最新资料',
          value: '1',
        },
        {
          name: '往期资料',
          value: '2',
        }
      ],
      list: [],
      loading: false,
      finished: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
      }
    }
  },
  filters: {
    setDate(vle) {
      if (vle) { return `${vle.substr(5, 2)}月${vle.substr(8, 2)} ${vle.substr(11, 5)}` }
      return '';
    }
  },
  computed: {
        active: {
            get() {
                return this.$store.state.common.forecast_active;
            },
            set(vle) {
                this.$store.commit("common/updateForecastActive",vle);
            },
        },
  },
  created() {
  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
    window.addEventListener('scroll', this.handleScroll);
    // this.$store.commit("common/",0)

  },
  methods: {
    onTabClick() {
      this.loading = false
      this.finished = false
      this.pagination.pageNo = 1;
      this.list = []
      setTimeout(() => {
        this.$refs.list.check();
      }, 100);
    },
    async onLoad() {
      // dateInterval : 1表示当天 , 2表示非当天 , null表示全部
      const res = await this.$HTTP.post(this, 'API_ARTICLE_LISTS', { dateInterval: this.tab[this.active].value, pageNo: this.pagination.pageNo, pageSize: this.pagination.pageSize, sortBy: "art_date desc, sort_index"}, {}, true) || []
      this.loading = false;
      this.pagination.pageNo += 1;
      this.list = this.list.concat(res.data)
      // 数据全部加载完成
      if (this.list.length >= res.totalSize * 1) {
        this.finished = true;
      }
    },
    getDetail(index){
      let artId = this.list[index].artId
      this.$router.push({name:'contentDetail',query:{artId:artId}})
    },
    handleScroll () {
   this.scroll  = document.documentElement &&  document.documentElement.scrollTop
  //  console.log(this.scroll)
  }
    
  },
  //每次进入该页面
  activated() {
          if(this.scroll >= 0){
            window.scrollTo(0, this.scroll);
            this.scroll = 0;
            window.addEventListener('scroll', this.handleScroll);
          }
  },
  // 每次离开该页面
  deactivated(){
     window.removeEventListener('scroll', this.handleScroll);

  }




};
</script>

<style lang="less">
.forecast_page {
  .van-tabs__line {
    background-color: #3abaf7;
  }
  .list_box {
    background-color: white;
    .list {
      display: flex;
      padding: 4% 0;
      margin: 0 4%;
      .photo {
        border-radius: 2px;
        overflow: hidden;
      }
      .cnt {
        width: 0;
        flex: 1;
        margin-left: 8px;
        .name {
          font-size: 16px;
          margin: 0;
        }
        .txt {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: #999;
          .price {
            color: #ff976a;
          }
        }
      }
    }
  }
}
</style>
